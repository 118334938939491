// 接頭辞
$prefix: wmpr-;

//==========================================================================
//Bsオリジナル変数の上書き・追加
//==========================================================================
//日本語環境
//【参照】
//採用honoka：https://honokak.osaka/
//https://sole-color-blog.com/blog/1380/
//https://anyushu.com/web/fonts-osusume/
//https://gray-code.com/html_css/specify-font-by-css/
@import "variables";
@import "mixins";


//==========================================================================
//Bsオリジナル
//ファイル構成
//【参照】
//https://getbootstrap.com/docs/4.3/getting-started/theming/
//https://cccabinet.jpn.org/bootstrap4/getting-started/theming
//========================================================================== */
@import "bootstrap/scss/bootstrap.scss";



// オリジナル変数
// フォント
// 日本語
$wmpr-font-family-serif: "Times New Roman", Times, "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; //honoka
$wmpr-font-family-sans: 'M PLUS Rounded 1c', sans-serif;

// 余白
$wmpr-spacer-x: $font-size-base;
$wmpr-spacer-y: $font-size-base * $line-height-base;


/* BSの上書き */
ol,
ul,
dl {
  margin-bottom: $spacer * 1.75;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
nav,
dt,
th,
.btn,
.card-header,
.card-title,
.lead,
.wmpr-page-title {
  font-family: $wmpr-font-family-sans;
  // @include font-smoothing;
}

h2,.h2 {
  margin-top: $wmpr-spacer-y * 2;
}
h3,.h3 {
  margin-top: $wmpr-spacer-y * 1.5;
}
h4,.h4 {
  margin-top: $wmpr-spacer-y * 1.5;
  margin-bottom: $wmpr-spacer-y * .75;
}
h5,.h5 {
  margin-top: $wmpr-spacer-y * 1.5;
  margin-bottom: $wmpr-spacer-y * .75;
}
h6,.h6 {
  margin-top: $wmpr-spacer-y * 1.5;
  margin-bottom: $wmpr-spacer-y * .5;
}

p {
  margin-bottom: $wmpr-spacer-y;
}

/* 禁則処理の追加 【参照】レスポンシブWEBデザイン本 */
p,
li,
dt,
dd,
th,
td,
pre {
  word-break: break-strict;
  -ms-word-break: break-strict;
  -ms-line-break: strict;
  line-break: strict;
}

hr {
  margin-top: $wmpr-spacer-y;
  margin-bottom: $wmpr-spacer-y;
}

.btn-primary,
.btn-secondary {
  color: $white;

  &:hover {
    color: $white;
  }
}

/* 位置 */
/* 天地左右中央 */
.#{$prefix}center-middle {
  @include wmpr-centering-elements;
}

/* 左右中央 */
.#{$prefix}center {
  @include wmpr-centering-elements(true, false);
}

/* 天地中央 */
.#{$prefix}middle {
  @include wmpr-centering-elements(false, true);
}


/* フォント */
.#{$prefix}font {
  &--sans {
    font-family: $wmpr-font-family-sans;
  }

  &--serif {
    font-family: $wmpr-font-family-serif;
  }

  &--lg {
    font-size: $font-size-lg;
  }

  &--sm {
    font-size: $font-size-sm;
  }
}

/* テキスト */
/* リンク */


a:visited:not(.btn) {
  color: #609;
}

// a[href^="http"]:not(.btn, .wmpr-img-link):after {
//   display: inline-block;
//   @include wmpr-fontawesome5;
//   content: '\f35d';
//   margin-left: $wmpr-spacer-x / 4;
//   margin-right: $wmpr-spacer-x / 4;
// }

.#{$prefix}text {
  &--lg {
    font-size: $font-size-lg;
  }

  &--sm {
    font-size: $font-size-sm;
  }

  &--emphasis {
    background-image: linear-gradient(rgba($wmpr-primary,0) 70%, $wmpr-primary 70%);
  }

  &--attention--swipe {
    display: flex;

    &:before {
      @include wmpr-fontawesome5;
      content: '\f0a6';
      display: inline-block;
      color: $wmpr-primary;
      margin-right: .25rem;
    }
  }

  // 突き出しインデント
  &--note {
    &__item {
      position: relative;
      margin-bottom: 0;
      padding-left: 1em;

      &:before {
        display: inline-block;
        content: '※';
        text-indent: -1em;
      }
    }
  }
}

/* ぶら下がりインデント */
.#{$prefix}indent {
  &--hanging {
    padding-left: 1em;
    text-indent: -1em;
  }
}




/* 見出し */
.#{$prefix}page-title {
  font-family: $wmpr-font-family-sans;
  overflow: hidden;
  margin-bottom: $wmpr-spacer-y * 3;

  &__container {
    position: relative;
    // display: block;
    // width: 100%;
    padding: 0;
    overflow: hidden;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: -30px;
    margin-right: -30px;



    &:before {
      display: block;
      content: "";
      padding-top: 42.857143%;
      background: url("../images/page-header_img_01.png") $white no-repeat center right / contain;
    }

    @include media-breakpoint-up(md) {
      &:before {
        padding-top: 25%;
      }
    }

    &:after {
      display: block;
      content: "";
      background: $wmpr-primary;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .7;
      // border-bottom: 4px dotted $white;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-left: $wmpr-spacer-x;
    padding-right: $wmpr-spacer-x;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__main {
    font-size: 1.75rem;
    font-weight: 500;
    font-feature-settings: "palt";
    letter-spacing: .0125em;
    line-height: 1.25;
    text-align: center;
    color: $white;
    // text-shadow: 0 0 12px rgba(255, 255, 255, 1), 0 0 25px rgba(255, 255, 255, 1), 0 0 25px rgba(255, 255, 255, 1), 0 0 25px rgba(255, 255, 255, 1), 0 0 25px rgba(255, 255, 255, 1);

    @include media-breakpoint-up(md) {
      font-size: 2.25rem;
    }
  }

  &__sub {
    font-weight: 500;
  }





  // $page-title-bg-images-cover: (
  //   "clinic",
  //   "shinryo",
  //   "implant",
  //   "access",
  //   "form",
  //   "ehon",
  //   "support",
  //   "recruit",
  //   "news"
  // );
  // @each $name in $page-title-bg-images-cover {
  //   &--#{$name} {
  //     background: url("../images/h1-#{$name}-sp@2x.png") center no-repeat $white / contain;
  //     @include media-breakpoint-up(sm) {
  //       background-image: url("../images/h1-#{$name}@2x.png");
  //     }
  //   }
  // }
}

.#{$prefix}section-title {
  color: $gray-800;
  font-family: $wmpr-font-family-sans;
  font-size: 2.25rem;
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: .025em;
  line-height: 1.25;
  // color: darken($wmpr-primary, 15%);
  color: $wmpr-primary;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: $wmpr-spacer-y * 2.5;
  margin-bottom: $wmpr-spacer-y * 2;
  align-items: center;
  flex-direction: column;

  &:before, &:after {
    content: '';
    display: block;
    // position: absolute;

    width: 160px;
    // top: 0;
  }

  &:before {
    border-top: 4px dotted $wmpr-primary;
    padding-bottom: 1rem;
  }

  &:after {
    border-bottom: 4px dotted $wmpr-primary;
    padding-top: 1rem;
    // top: auto;
    // bottom: 0;
  }
}

.#{$prefix}h {
  &2 {
    color: $gray-800;
    font-family: $wmpr-font-family-sans;
    font-size: 2.25rem;
    font-weight: 500;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    // border-top: 4px dotted $wmpr-primary;
    border-bottom: 4px dotted $wmpr-primary;
    padding-left: .25rem;
    padding-bottom: 1rem;
    margin-top: $wmpr-spacer-y * 2;
    // text-align: center;
    // position: relative;
    // display: flex;
    // justify-content: center;

    // &:before, &:after {
    //   content: '';
    //   display: inline-block;
    //   position: absolute;
    //   border-top: 4px dotted $wmpr-primary;
    //   width: 160px;
    //   top: 0;
    // }

    &:after {
      top: auto;
      bottom: 0;
    }
  }

  &3 {
    color: $gray-800;
    font-family: $wmpr-font-family-sans;
    font-size: 2rem;
    font-weight: 700;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    padding: .5rem;
    padding-left: 1rem;
    border-left: 3px solid $wmpr-primary;
    margin-top: $wmpr-spacer-y * 1.5;
  }

  &4 {
    font-size: 1.75rem;
    font-family: $wmpr-font-family-sans;
    font-weight: 700;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    // color: darken($wmpr-primary, 15%);
    color: $wmpr-primary;
    margin-top: $wmpr-spacer-y * 1.5;
    margin-bottom: $wmpr-spacer-y * .75;
  }

  &5 {
    color: $gray-800;
    font-family: $wmpr-font-family-sans;
    font-size: 1.5rem;
    font-weight: 500;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    border-bottom: 1px solid $wmpr-primary;
    padding-bottom: .5rem;
    margin-top: $wmpr-spacer-y * 1.5;
    margin-bottom: $wmpr-spacer-y * .75;
  }

  &6 {
    // color: darken($wmpr-secondary, 5%);
    color: $wmpr-primary;
    font-family: $wmpr-font-family-sans;
    font-size: 1.25rem;
    font-weight: 700;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    margin-top: $wmpr-spacer-y * 1.5;
    margin-bottom: $wmpr-spacer-y * .5;
  }
}


/* リスト */
.#{$prefix}list {

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  &--icon {
    > .wmpr-list__item {
      padding-left: 1.25em;

      &:before {
        position: absolute;
        display: inline-block;
        text-decoration: none;
        color: $gray-500;
      }
    }

    // アイコン配列
    $list-icon: (
      circle f111 900 0.75em 0.25em 0.5em,
      caret-right f0da 900 0 0.25em null,
      caret-square-right f152 900 0 0 null,
      play-circle f144 900 0 0 null,
      // arrow-circle-right f0a9 900 0 0 null,
      angle-right f105 900 0 0.25em null,
      chevron-angle-right f138 900 0 0 null,
      exclamation-circle f06a 900 0 0 null,
      // mobile-alt f3cd 900 0 0 null,
      // map-marker-alt f3c5 900 0 0 null
    );

    @each $name, $glyph, $font-weight, $top, $left, $font-size in $list-icon {
      &--#{$name} {
        > .wmpr-list__item:before {
          content: #{'"' + '\\' + $glyph + '"'};
          font-weight: $font-weight;
          font-family: "Font Awesome 5 Free";
          top: $top;
          left: $left;
          font-size: $font-size;
        }

        &.wmpr-list--divider {
          > .wmpr-list__item:before {
            left: calc(#{$wmpr-spacer-x} + #{$left});
          }
        }
      }
    }

    &--note {
      > .wmpr-list__item {
        padding-left: 1em;

        &:before {
          display: inline-block;
          content: '※';
          text-indent: -1em;
          font-family: $wmpr-font-family-sans;
          color: $gray-900;
        }
      }
    }

    > .wmpr-list__item.wmpr-list--icon--none:before {
      content: none;
    }
  }

  &--ordered {
    counter-reset: num;

    > .wmpr-list__item {
        padding-left: 1.25em;

      &:before {
        position: absolute;
        display: inline-block;
        counter-increment: num;
        content: counter(num) '. ';
        top: 0;
        left: 0;
        font-weight: bold;
      }
    }

    &--encircle {

      > .wmpr-list__item {
        padding-left: 1.25em;

        &:before {
          content: counter(num);
          background: $gray-700;
          border-radius: 50%;
          width: 1.125em;
          height: 1.125em;
          line-height: 1.125;
          text-align: center;
          display: inline-block;
          position: absolute;
          top: calc((1.75em - 1.125em) / 2);
          left: 0;
          color: $white;
        }
      }

      &--lg {
        > .wmpr-list__item {
          padding-left: 2.75em;

          &:before {
            width: 1.5em;
            height: 1.5em;
            line-height: 1.5;
            font-size: 1.25em;
            top: calc((1.75em - 1.875em) / 2);
          }
        }
      }
    }
  }

  &--marker {
    &--primary {
      > .wmpr-list__item:before {
        color: $wmpr-primary;
      }
    }

    &--secondary {
      > .wmpr-list__item:before {
        color: $wmpr-secondary;
      }
    }

    &--ordered--encircle {
      &--primary {
        > .wmpr-list__item:before {
          background: $wmpr-primary;
        }
      }

      &--secondary {
        > .wmpr-list__item:before {
          background: $wmpr-secondary;
        }
      }
    }

    &--middle {
      > .wmpr-list__item:before {
        @include wmpr-centering-elements(false, true);
      }
    }
  }

  // 横並びリスト
  &--inline {
    font-size: 0;

    > .list-inline-item {
      font-size: 1rem;
    }
  }

  // &.text-left {
  //   > li {
  //     a {
  //       text-align: left;
  //     }
  //   }
  // }

  &--definition {
    > .wmpr-list__item > *:last-child {
      margin-bottom: 0;
    }

    &--horizontal {
      > .wmpr-list__item {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;

          > .wmpr-list__title {
            max-width: 3em;
            flex: 0 0 3em;
            margin-right: 2em;
          }
        }
      }
    }

  // &--row-link {
  //   > .wmpr-list__item:hover {
  //     background: $wmpr-primary-light;
  //   }
  // }

    // .wmpr-list__titleのカスタマイズ
    // &--foo {
    //   @include media-breakpoint-up(md) {
    //     > .wmpr-list__title {
    //       max-width: 3em;
    //       flex: 0 0 3em;
    //     }
    //   }
    // }
  }

  &--divider {
    > .wmpr-list__item {
      margin-bottom: 0;
      padding: $wmpr-spacer-x;

      &:not(:last-child) {
        border-bottom: 1px solid $gray-500;
      }

      &:hover {
        background: $wmpr-primary-light;
      }
    }

    &.wmpr-list--icon, &.wmpr-list--ordered {
      > .wmpr-list__item {
        padding-left: calc(#{$wmpr-spacer-x} + 1.25rem);

        &:before {
          top: $wmpr-spacer-x;
        }
      }
    }

    &.wmpr-list--ordered {
      > .wmpr-list__item:before {
        left: $wmpr-spacer-x;
      }
    }

    &.wmpr-list--ordered--encircle {
      > .wmpr-list__item {
        padding-left: calc(#{$wmpr-spacer-x} + 1.25em);

        &:before {
          top: calc(#{$wmpr-spacer-x} + .375em);
          left: $wmpr-spacer-x;
        }
      }
    }

    &.wmpr-list--ordered--encircle--lg {
      > .wmpr-list__item {
        padding-left: calc(#{$wmpr-spacer-x} + 2.75em);

        &:before {
          top: calc(#{$wmpr-spacer-x} - .125em);
          left: $wmpr-spacer-x;
        }
      }
    }

    &.wmpr-list--marker--middle {
      > .wmpr-list__item:before {
        @include wmpr-centering-elements(false, true);
      }
    }


    &--inline {
      > .wmpr-list__item {
        margin-right: 0;
        position: relative;
        padding-left: 1rem;
        padding-right: 1rem;

        &:not(:last-child):after {
          content: '';
          right: 0;
          width: 1px;
          height: 16px;
          background-color: $gray-500;
          @include wmpr-centering-elements(false, true);
        }
      }
    }
  }


  // &--table {
  //   > .wmpr-list__item {
  //     position: relative;
  //     border-bottom: 2px dotted $gray-500;

  //     &:hover {
  //       background: lighten($wmpr-primary, 78%);
  //     }

  //     dd {
  //       position: static;
  //     }
  //   }
  // }



  // &-group {
  //   .list-group-item-action {
  //     &:hover {
  //       color: $wmpr-primary;
  //     }
  //   }

  //   &--icon {
  //     .list-group-item {
  //       padding-left: calc(.5rem + 1em);

  //       &:before {
  //         display: inline-block;
  //         font-family: 'Font Awesome 5 Free';
  //         font-weight: 900;
  //         content: '\f0da';
  //         text-indent: -1em;
  //         color: $wmpr-primary;
  //       }
  //     }
  //   }
  // }
}


/* テーブル */
.#{$prefix}table {
  thead th, th {
    vertical-align: middle;
  }

  &--uketsukejikan {
    td {
      vertical-align: middle;
    }
  }

  &--price {
    td {
      vertical-align: middle;
    }

    tr > td {
      &:last-child {
        text-align: right;
      }
    }
  }
}



/* グローバルナビゲーション */
.#{$prefix}navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    padding: $spacer $spacer * 1.25;
  }

  &-collapse {
    @include media-breakpoint-up(lg) {
      margin-left: auto;
      flex-grow: 1;
    }
  }

  &-nav {
    align-items: center;

    .nav-item {
      line-height: 1.25;
      flex-basis: auto;
    }

    .nav-link {
      color: $white;

      &:visited {
        color: $white;
      }
    }
    @include media-breakpoint-up(lg) {
      margin-right: -$spacer * .5;

      .nav-item {
        position: relative;
        flex-basis: 0;

        /* 項目の区切り線 */
        &:first-child:before,
        &:after {
          content: '';
          display: block;
          border-left:2px dotted $wmpr-primary;
          width: 0;
          height: 3rem;
          right: 0;
          @include wmpr-centering-elements(false, true);
        }

        &:first-child:before {
          left: 0;
          right: auto;
        }

        .nav-link {
          color: $body-color;
        }

        .nav-link:hover:before {
          content: '';
          display: block;
          background: $wmpr-primary;
          width: 1.75rem;
          height: 2px;
          border-radius: 50rem;
          @include wmpr-centering-elements(true, false);
          bottom: .25rem;
        }
      }
    }
  }
}

/* グローバルナビゲーションDWライブラリ機能使用時の設定 */
// リスト
$page-name: (
  "home" "1",
  "news" "2",
  "about" "3",
  "shinryo" "4",
  "map" "5",
  "gallery" "6"
);

@each $name, $number in $page-name {
  .#{$name} .wmpr-navbar-nav .nav-item:nth-child(#{$number}) .nav-link:before {
    content: '';
    display: block;
    background: $wmpr-primary;
    width: 1.75rem;
    height: 2px;
    border-radius: 50rem;
    @include wmpr-centering-elements(true, false);
    bottom: .25rem;
  }
}


// .#{$prefix}navbar-nav {
//   .nav-link.active:before {
//     content: '';
//     display: block;
//     background: lighten($wmpr-primary, 20%);
//     width: 1.75rem;
//     height: 2px;
//     border-radius: 50rem;
//     @include wmpr-centering-elements(true, false);
//     bottom: .25rem;
//   }
// }


/* Offcanvas（グロナビSP表示） */
@include media-breakpoint-down(md) {
  .offcanvas-collapse {
    position: fixed;
    /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 90%;
    // padding-right: 1rem;
    // padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: rgba($wmpr-primary, .9);
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    // transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
    // transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
  }

  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

/* ハンバーガーアイコン */
// 変数
// $wmpr-hamburger__line-color: $gray-900;
$wmpr-hamburger__line-color: $wmpr-primary;
// $wmpr-hamburger__wrap-line-color: $wmpr-secondary;
$wmpr-hamburger__wrap-line-color: transparent;
$wmpr-hamburger__line-h: 2px;
$wmpr-hamburger__line-w: 28px;
$wmpr-hamburger__line-spacer: 8px;
$wmpr-hamburger__icon-h: $wmpr-hamburger__line-h * 2 + $wmpr-hamburger__line-spacer * 2;
$wmpr-hamburger__icon-w: $wmpr-hamburger__line-w;
$wmpr-hamburger__text-size: .5rem;
$wmpr-hamburger-w: 50px;
$wmpr-hamburger-h: 43px;
$wmpr-hamburger-border-radius: 4px;
$wmpr-hamburger-flex-direction: column;

// $wmpr-hamburger-w: 90px;
// $wmpr-hamburger-h: null; //nullを指定すると出力されない
// $wmpr-hamburger-border-radius: 8px;
// $wmpr-hamburger-flex-direction: row;

.wmpr-hamburger-button {
  position: relative;
  display: inline-block;
  text-decoration: none;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.wmpr-hamburger {
  width: $wmpr-hamburger-w;
  height: $wmpr-hamburger-h;
  border-radius: $wmpr-hamburger-border-radius;
  border: 1px solid $wmpr-hamburger__wrap-line-color;
  box-shadow: 0 0 2rem transparent;
  outline: none;
  // -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: $wmpr-hamburger-flex-direction;
  align-items: center;
  justify-content: space-between;
}

.wmpr-hamburger:hover,
.wmpr-hamburger:focus {
  box-shadow: 0 0 .5rem rgba(255, 255, 255, .5 );
}

.wmpr-hamburger__icon {
  display: block;
  position: relative;
  height: $wmpr-hamburger__icon-h;
  width: $wmpr-hamburger__line-w;
}

.wmpr-hamburger__text {
  display: block;
  font-size: $wmpr-hamburger__text-size;
  width: $wmpr-hamburger-w - 2px;
}

.wmpr-hamburger__line {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: $wmpr-hamburger__line-h;
  background-color: $wmpr-hamburger__line-color;
  transition: all .3s ease-in-out;
}
.wmpr-hamburger__line:before,
.wmpr-hamburger__line:after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  /* background-color: inherit; */
  background-color: $wmpr-hamburger__line-color;
  content: '';
  transition: inherit;
}
.wmpr-hamburger__line:before {
  top: -$wmpr-hamburger__line-spacer;
}
.wmpr-hamburger__line:after {
  top: $wmpr-hamburger__line-spacer;
}

.wmpr-hamburger[aria-expanded="true"] .wmpr-hamburger__line {
  background-color: transparent;
}

.wmpr-hamburger[aria-expanded="true"] .wmpr-hamburger__line::before {
  // -webkit-transform: translateY($wmpr-hamburger__line-spacer) rotate(45deg);
  //     -ms-transform: translateY($wmpr-hamburger__line-spacer) rotate(45deg);
  transform: translateY($wmpr-hamburger__line-spacer) rotate(45deg);
}

.wmpr-hamburger[aria-expanded="true"] .wmpr-hamburger__line::after {
  // -webkit-transform: translateY(-$wmpr-hamburger__line-spacer) rotate(-45deg);
  //-ms-transform: translateY(-$wmpr-hamburger__line-spacer) rotate(-45deg);
  transform: translateY(-$wmpr-hamburger__line-spacer) rotate(-45deg);
}

.wmpr-hamburger-buton--visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

@include media-breakpoint-up(lg) {
  .wmpr-hamburger-button {
    display: none;
  }
}




/* ----------------------------
ボタン
---------------------------- */
.#{$prefix}btn {
  // min-width: 300px;
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: .025em;

  &--primary-light {
    background: lighten($wmpr-primary, 23%);
    border: $wmpr-primary-light;
    color: $wmpr-primary;

    &:hover {
      opacity: .8;
    }
  }

  &--white {
    background: $white;
    border: $white;
    color: $gray-900;

    &:hover {
      opacity: .8;
    }
  }

  &-outline {
    &--white {
      color: $white;
      border-color: $white;

      &:hover {
        color: $gray-900;
        background-color: $white;
      }
    }
  }

  // サイズ
  &--xl {
    font-size: 1.5rem;
  }

  // アイコン
  &--icon {
    position: relative;

    &:before, &:after {
      display: inline-block;
      vertical-align: middle;
    }

    &--before:before {
      margin-right: $wmpr-spacer-x / 2;
    }

    &--after:after {
      margin-left: $wmpr-spacer-x / 2;
    }

    &--left:before {
      left: $btn-padding-x;
      @include wmpr-centering-elements(false, true)
    }

      // アイコン右側配置
    &--right:before {
      right: $btn-padding-x;
      @include wmpr-centering-elements(false, true)
    }

    // アイコン配列
    $btn-icons: (
      "caret-right" "f0da" ".75em",
      "chevron-left" "f053" ".75em",
      "chevron-right" "f054" ".75em",
      "chevron-up" "f077" ".75em",
      "chevron-down" "f078" ".75em",
      "caret-square-right" "f152" "1.125em",
      "mobile-alt" "f3cd" "1.125em",
      "angle-double-right" "f101" "1.125em",
      "exclamation-circle" "f06a" "1.125em",
      "map-marker-alt" "f3c5" "1.125em",
      "external-link-alt" "f35d" "1.125em",
      "comment-dots" "f4ad" "1.125em",
      "briefcase-medical" "f469" "1.125em",
      "notes-medical" "f481" "1.125em",
      "images" "f302" "1.125em",
      "phone-volume" "f2a0" "1.125em"
    );

    @each $name, $glyph ,$size in $btn-icons {
      &--#{$name} {
        &.wmpr-btn--icon--before:before,
        &.wmpr-btn--icon--left:before,
        &.wmpr-btn--icon--right:before {
          @include wmpr-fontawesome5;
          content: #{'"' + '\\' + $glyph + '"'};
        }

        &.wmpr-btn--icon--after:after {
          @include wmpr-fontawesome5;
          content: #{'"' + '\\' + $glyph + '"'};
        }

        &.wmpr-btn--icon--left {
          // padding-left: #{$btn-padding-x};
          padding-left: calc(#{$btn-padding-x} + #{$size});
        }

        &.wmpr-btn--icon--right {
          // padding-left: #{$btn-padding-x};
          padding-right: calc(#{$btn-padding-x} + #{$size});
        }

        &.btn-sm {

          &.wmpr-btn--icon--left {
            padding-left: calc(#{$btn-padding-x-sm} + #{$size});

            &:before {
              left: .5rem;
            }
          }

          &.wmpr-btn--icon--right {
            padding-right: calc(#{$btn-padding-x-sm} + #{$size});

            &:before {
              left: auto;
              right: .5rem;
            }
          }
        }
      }
    }

    &--phone-volume:before {
      transform: rotate(-45deg) translateX(5px);
    }

    // アイコンカラー
    &--primary:before {
      color: $wmpr-primary;
    }

    &--secondary:before {
      color: $wmpr-secondary;
    }

    // アイコンサイズ
    &--lg:before {
      font-size: 1.5em;
      line-height: 0;
    }
  }

  &--pagetop {
    opacity: .6;
    color: $white;
    background-color: $gray-700;
    line-height: 1.25;
    position: fixed;
    z-index: 9999;
    right: $spacer;
    bottom: $spacer;

    &:hover {
      transition: all .3s;
      opacity: inherit;
      color: $white;
    }

    &:focus {
      color: $white;
    }
  }
}

/* ページの先頭に戻るボタン */
// .#{$prefix}pagetop-container {
//   position: fixed;
//   z-index: 9999;
//   right: $spacer;
//   bottom: $spacer;
// }


// .#{$prefix}btn-pagetop {
//   opacity: .6;
//   color: $white;
//   background-color: $gray-700;
//   line-height: 1.25;

//   &:hover {
//     transition: all .3s;
//     opacity: inherit;
//     color: $white;
//   }

//   &:focus {
//     color: $white;
//   }
// }



/* バックグラウンド */
.#{$prefix}bg {
  &-light {
    &--primary {
      background-color: $wmpr-primary-light;
    }

    &--secondary {
      background-color: $wmpr-secondary-light;
    }
  }

  &-gradient {
    &--type {
      background: linear-gradient(90deg, #939a72 50%, #a3c1dd 50% 100%);
    }
    &--type2 {
      background: linear-gradient(90deg, #b2a096 50%, #a0b19e 50% 100%);
    }
  }

  &-texture {
    &--primary {
      background-image: url(../images/main-bg-alpha50.png);
      background-position: top center;
    }
  }
}




/* カード */
.#{$prefix}card {
  // border: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  > .card-body {
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;

    @include media-breakpoint-up(sm) {
      padding: $spacer * 1.5;
    }

    & > *:first-child {
      margin-top: 0;
    }
  }

  &__thumb {
    position: relative;
    // background: lighten($wmpr-primary-light, 7%);
    background: $wmpr-primary-light;

    &--4by3 {
      padding-top: 75%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      font-family: 'object-fit: contain;';
    }
  }

  .card-title > .stretched-link {
    color: $gray-800;
    text-decoration: none;

    &:visited {
      color: $gray-800;
    }
  }

  &--primary {
    border: 8px solid $wmpr-primary-light;
  }

  &--secondary {
    border: 8px solid $wmpr-secondary-light;
  }

  &--news {
    .wmpr-list--definition--horizontal > .wmpr-list__item > .wmpr-list__title {
      // color: darken($wmpr-primary, 15%);
      color: $wmpr-primary;

      @include media-breakpoint-up(md) {
        max-width: 12em;
        flex: 0 0 12em;
      }
    }
  }

  &--lightbox:hover {
    opacity: .7;
  }

  // $card-bg-images-cover: (
  //   "ehon",
  //   "implant",
  //   "yoyaku",
  //   "toiawase"
  // );
  // @each $name in $card-bg-images-cover {

  //   &--#{$name} {
  //     background: center / cover no-repeat url("../images/card-#{$name}@2x.png");

  //     .card-text {
  //       line-height: 3;
  //     }
  //   }
  // }

  // &--type {
  //   border: .25rem solid #f9e2e2;
  //   background: #fdf4f4;
  // }
}


/* バナー */
.#{$prefix}bnr {
  &:hover {
    opacity: .8;
  }
}




// 背景画像拡大アニメーション
// .#{$prefix}bnr--transform {
//   font-family: $font-serif;

//   a {
//     position: relative;
//     z-index: 0;
//     color: $white;
//     text-decoration: none;
//     letter-spacing: .25em;

//     &:before {
//       transition: .3s ease-in-out;
//       transform: scale(1);
//       position: absolute;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       left: 0;
//       content: "";
//       z-index: -10;
//     }

//     &:hover:before {
//       transform: scale(1.3);
//     }

//     &:after {
//       background: rgba($black, .5);
//       transition: .3s ease-in-out;
//       position: absolute;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       left: 0;
//       content: "";
//       z-index: -5;
//     }

//     &:hover:after {
//       transform: scale(1.3);
//       background: rgba($black, .3);
//     }
//   }
// }


/* .wmpr-top-shnryo {
  &:before {
    display: block;
    content: '';
    background: #d8e0d8;
    position: absolute;
    top: -78px;
    left: 0;
    right: 0;
    bottom: 0;
    clip-path: url(#myClip);
  }
} */

/* フッター */
.#{$prefix}footer-map__embed > .embed-responsive:before {
  padding-top: 75%;

  @include media-breakpoint-up(md) {
    padding-top: 42.85714%;
  }
}

/* フッターグロナビ */
.wmpr-footer-gnav {
  color: $white;

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

/* バナー */
/* .card-deckで並べる場合 */
.#{$prefix}card-deck--banner {
  .#{$prefix}card--banner {
    @include media-breakpoint-up(sm) {
      flex: 0 0 calc(50% - 32px);
      max-width: calc(50% - 32px);
      margin-bottom: $wmpr-spacer-x * 2;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 calc(33.3333333% - 32px);
      max-width: calc(33.3333333% - 32px);
      margin-bottom: $wmpr-spacer-x * 2;
    }
  }
}
/* /.card-deckで並べる場合 */

/* .row > .colで並べる場合 */
.#{$prefix}row-card--banner {
  > .col {
    margin-bottom: $wmpr-spacer-x * 2;
  }
}
/* /.row > .colで並べる場合 */


.#{$prefix}card--banner {
  border-radius: 0;

  &:hover {
    opacity: .7;
  }

  &--anime {
    // background: $wmpr-secondary;
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &:before {
      display: block;
      content: "";
      padding-top: 42.85714%;
      transition: all .3s ease-out;
      // opacity: .3;
    }

    &:hover {
      opacity: 1;
    }

    &:hover:before {
      // opacity: .5;
      transform: scale(1.1);
    }

    // 背景画像
    $bg-img: (
      "about",
      "shinryo",
      "map",
      "gallery",
      "recruit",
      "support"
    );

    @each $name in $bg-img {
      &.#{$name}:before {
        background: linear-gradient(rgba($wmpr-primary, .7), rgba($wmpr-primary, .7)), url(../images/bnr_#{$name}_01.png) no-repeat center/cover;
      }

      &.#{$name}:hover:before {
        background: linear-gradient(rgba($wmpr-primary, .5), rgba($wmpr-primary, .5)), url(../images/bnr_#{$name}_01.png) no-repeat center/cover;
      }

    }

    > .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      text-align: center;
      color: #fff;
      font-family: $wmpr-font-family-sans;
    }

    .card-title {
      color: $white;
      margin-bottom: $wmpr-spacer-x / 4;

      > .stretched-link {
        color: $white;
        text-decoration: none;

        &:visited {
          color: $white;
        }
      }
    }

    .card-text {
      line-height: 1.25;
    }
  }
}





/* ヘッダー・フッター・セクション */
.#{$prefix}section {
  > *:first-child {
    margin-top: 0;
  }
}

.#{$prefix}site {
  &-logo {
    line-height: 1;
    flex: 0 0 60%;
    max-width: 330px;

    @include media-breakpoint-up(md) {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
  }
  /* brand（サイトロゴ） */
  /* &-logo {
    font-size: 1rem;

    img {
      display: block;
      max-width: 200px;

      @include media-breakpoint-up(lg) {
        max-width: 250px;
      }
    }
  } */

  &-header {
    background: $white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    &__utilities {
      padding-top: $spacer * .25;
      padding-bottom: $spacer * .25;

      @include media-breakpoint-up(sm) {
        padding-top: $spacer * .5;
        padding-bottom: $spacer * .5;
      }

      @include media-breakpoint-up(lg) {
        padding-top: $spacer;
        padding-bottom: $spacer;
      }

      &__inner {
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }
      }

      &__item {
        display: flex;
        align-items: center;

        &--main {
          justify-content: space-between;
        }
      }

      .wmpr-btn--tel {
        font-size: 1.5em;
      }
    }
  }

  &-footer {

    &__overview {
      &__title {
        font-size: $font-size-base * 1.25;
        font-weight: 500;
        font-feature-settings: "palt";
        letter-spacing: .025em;
        line-height: 1.25;
        color: darken($wmpr-primary, 5%);
        // padding-left: $wmpr-spacer-x / 4;
        padding-bottom: $wmpr-spacer-x / 2;
        margin-bottom: $wmpr-spacer-y / 2;
        margin-top: $wmpr-spacer-y;
        border-bottom: 3px dotted $wmpr-primary;
      }

      &__subtitle {
        font-size: $font-size-base * 1.125;
        font-weight: 500;
        font-feature-settings: "palt";
        letter-spacing: .025em;
        line-height: 1.25;
        color: darken($wmpr-primary, 5%);
        margin-top: $wmpr-spacer-y;
        margin-bottom: $wmpr-spacer-y / 2;
      }
    }

    &__map {
      &__embed {
        > .embed-responsive:before {
          padding-top: 75%;

          @include media-breakpoint-up(md) {
            padding-top: 42.85714%;
          }
        }
      }
    }

    &__gnav {
      color: $white;
      background-color: $wmpr-primary;
      padding-top: $spacer *1.5;
      padding-bottom: $spacer *1.5;

      a {
        color: $white;

        &:hover {
          color: $white;
        }
      }

      .wmpr-list--divider--inline {
        > .wmpr-list__item:not(:last-child):after {
          border-right: 1px solid $white;
        }
      }
    }

    &__copyright {
      background-color: $wmpr-primary;
      text-align: center;
      color: $white;
      padding-top: $spacer;
      padding-bottom: $spacer;
    }
  }
}




/* ホームページ */
.#{$prefix}home {

  /* メインビジュアルスライダー */
  &-slider {
    position: relative;
    // background: url(../images/main-bg-alpha50.png) $wmpr-primary-light top center;
    background: $wmpr-primary-light;
    overflow: hidden;

    &__wrapper {
      position: relative;
      /*コントローラーを表示する場合は削除かコメントアウト*/
      // padding-bottom: 84px;

      border-bottom-left-radius: 50% 20%;
      border-bottom-right-radius: 50% 20%;
      overflow: hidden;
      padding-left: 30px;
      padding-right: 30px;
      margin-left: -30px;
      margin-right: -30px;
      z-index: 1;


      // @include media-breakpoint-up(md) {
      //   margin-bottom: 0;
      //   padding-bottom: 0;
      // }

      // &.container-fluid {
      //   padding-right: 0;
      //   padding-left: 0;

      //   @include media-breakpoint-up(md) {
      //     padding-right: 16px;
      //     padding-left: 16px;
      //   }
      // }

      /*前後矢印をアイコンフォントにする場合
      前矢印のアイコンフォント
      .sp-next-arrow:before {
        font-family: FontAwesome !important;
        content: "\f105" !important;
        font-size: 64px;
      }
      後矢印のアイコンフォント
      .sp-previous-arrow:before {
        font-family: FontAwesome !important;
        content: "\f104" !important;
        font-size: 64px;
      }*/

      /*前後矢印を画像にする場合
      次矢印のアイコンフォント
      .sp-next-arrow:before {
          content:' ' !important;
        display: block;
        background: url(../images/arrow-right.png) no-repeat left top;
        text-indent: -9999px;
        width: 40px;
        height: 40px;
      }
      前矢印のアイコンフォント
      .sp-previous-arrow:before {
        content:' ' !important;
        display: block;
        background: url(../images/arrow-left.png) no-repeat left top;
        text-indent: -9999px;
        width: 40px;
        height: 40px;
      }*/

      /*ナビゲーションボタンの色*/
      .sp-button {
        border: 2px solid #ccc;
      }

      .sp-selected-button {
        background-color: #ccc;
      }
    }
  }

  /* メインビジュアルセールスコピー、リードコピー */
  &-catchcopy {
    font-family: $wmpr-font-family-sans;
    font-weight: 700;
    z-index: 1000;

    color: darken($wmpr-secondary, 20%);
    padding: $wmpr-spacer-y $wmpr-spacer-x;
    text-align: center;

    @include media-breakpoint-up(md) {
      // @include wmpr-centering-elements;
      position: absolute;
      // right: 0;
      // bottom: 24vh;
      @include wmpr-centering-elements;
      top: 42%;
      padding: 0 $wmpr-spacer-x * 2;
      /* IE対策：スライダーの上にposition:absoluteでのせたテキストが点滅する不具合 */;
      width: 100%;
      // text-align: center;
    }

    *:last-child {
      margin-bottom: 0;
    }


    &__sales {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.25;
      // margin-top: 56px;
      margin-bottom: .5em;
      letter-spacing: .125em;
      // color: $gray-900;
      // text-align: center;
      position: relative;
      text-shadow: 0 0 12px rgba($white, 1), 0 0 25px rgba($white, 1), 0 0 25px rgba($white, 1), 0 0 25px rgba($white, 1), 0 0 25px rgba($white, 1);

      @include media-breakpoint-up(md) {
        font-size: 3.25vw;
        margin-top: 0;
      }

      &:after {
        content: attr(data-site-copy-en)"";
        position: absolute;
        display: block;
        font-size: .5em;
        top: -1.5em;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
    }

    &__lead {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0;
      // color: #555;
      // text-align: center;
      text-shadow: 0 0 12px rgba($white, 1), 0 0 25px rgba($white, 1), 0 0 25px rgba($white, 1), 0 0 25px rgba($white, 1), 0 0 25px rgba($white, 1);

      @include media-breakpoint-up(md) {
        font-size: 2.25vw;
      }
    }
  }

  &-section {
    padding-top: $wmpr-spacer-y * 3;
    padding-bottom: $wmpr-spacer-y * 3;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      backdrop-filter: blur(1px);
    }

    // お知らせ
    &.news {
      // background: url(../images/main-bg-alpha50.png) $wmpr-primary-light top center;
      background: $wmpr-primary-light;
      padding-top: $wmpr-spacer-y;
      padding-bottom: $wmpr-spacer-y;

      @include media-breakpoint-up(sm) {
        // padding-top: 0;
        padding-top: $wmpr-spacer-y * 3;
        padding-bottom: $wmpr-spacer-y * 3;
      }

      @include media-breakpoint-up(md) {
        // margin-top: -6vh;
        // border: 4px solid $wmpr-primary;

        .card {
          z-index: 2;
        }
      }
      // @include media-breakpoint-up(lg) {
      //   margin-top: -8vh;
      // }
    }

    // クリニック紹介
    &.about {
      background: linear-gradient(rgba($white, .8), rgba($white, .8)), $wmpr-primary url(../images/bg_02.png) no-repeat top right/contain;
    }

    // 診療案内
    &.shinryo {
      background: linear-gradient(rgba($white, .8), rgba($white, .8)), url(../images/bg_01.png) no-repeat top right/contain;
    }

    // 会社概要
    &.kaisyagaiyo {
      @include media-breakpoint-up(md) {
        .wmpr-list--definition--horizontal > .wmpr-list__item > .wmpr-list__title {
          max-width: 7em;
          width: 7em;
          flex: 0 0 7em;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .news .wmpr-list--definition--horizontal > .wmpr-list__item > .wmpr-list__title {
    max-width: 12em;
    flex: 0 0 12em;
  }
}

/* mainのpadding-topのレンダリングの不具合一時対策 */
main {
  padding-top: 54px;

  @include media-breakpoint-up(sm) {
    padding-top: 62px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 164px;
  }
}

/* 健康サポート */
#column {

  h2 {
    color: $gray-800;
    font-size: 2rem;
    font-weight: 700;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    padding: .5rem;
    padding-left: 1rem;
    border-left: 3px solid $wmpr-primary;
    margin-top: $wmpr-spacer-y * 1.5;
  }

  h3 {
    font-size: 1.75rem;
    font-weight: 700;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    color: darken($wmpr-primary, 15%);
    margin-top: $wmpr-spacer-y * 1.5;
    margin-bottom: $wmpr-spacer-y * .75;
  }

  h4 {
    color: $gray-800;
    font-size: 1.5rem;
    font-weight: 500;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    border-bottom: 1px solid $wmpr-primary;
    padding-bottom: .5rem;
    margin-top: $wmpr-spacer-y * 1.5;
    margin-bottom: $wmpr-spacer-y * .75;
  }

  h5 {
    color: darken($wmpr-primary, 5%);
    font-size: 1.25rem;
    font-weight: 700;
    font-feature-settings: "palt";
    letter-spacing: .025em;
    line-height: 1.25;
    margin-top: $wmpr-spacer-y * 1.5;
    margin-bottom: $wmpr-spacer-y * .5;
  }

  ul {
    padding-left: 0;
    list-style: none;

    > li {
      position: relative;
      padding-left: 1.25em;

      &:before {
        position: absolute;
        display: inline-block;
        text-decoration: none;
        content: "\f0da";
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        top: 0;
        left: 0.25em;
        color: #82c079;
      }

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
  }

  ol {
    > li {
      position: relative;
      padding-left: 1.25em;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
  }

  table {
    border-collapse: collapse;
    margin-bottom: 1rem;
    color: #212529;
    border: 1px solid #dee2e6;

    th, td {
      border: 1px solid #dee2e6;
    }
  }
}

/* お知らせ更新機能 */
.sc-content p {
  padding: 1.5rem;
  border: 8px solid $wmpr-primary-light;
  border-radius: 0.25rem;
  background-color: $white;
}


/* 最後の要素のマージンボトムを削除 */
section > *:last-child,
[class*="container"] > *:last-child,
[class*="row"] > *:last-child,
[class^="col"] > *:last-child,
[class^="wmpr-list__item"] > *:last-child,
[class^="wmpr-list__body"] > *:last-child {
  margin-bottom: 0;
}

section > *:first-child,
[class*="container"] > *:first-child,
[class*="row"] > *:first-child,
[class^="col"] > *:first-child,
[class^="wmpr-list__item"] > *:first-child,
[class^="wmpr-list__body"] > *:first-child {
  margin-top: 0;
}