// mixin: 天地左右中央、左右中央、天地中央の切り分け
// https://www.nxworld.net/tips/sass-mixin-snippets.html
@mixin wmpr-centering-elements($horizontal: true, $vertical: true) {
  position: absolute;

  @if $horizontal and $vertical {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if $horizontal {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @else if $vertical {
    top: 50%;
    transform: translate(0, -50%);
  }
}


// 背景斜線
@mixin wmpr-bg-pattern-striped45 {
  background-image: linear-gradient(-45deg, rgba($white, .5) 25%, transparent 25%, transparent 50%, rgba($white, .5) 50%, rgba($white, .5) 75%, transparent 75%, transparent);
  background-size: 4px 4px;
}


// フォント
// Font Awesome 5
@mixin wmpr-fontawesome5 {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}


// Google Map Youtubeなどレスポンシブ対応
// スタンダード4:3：75%（100 / 4 x 3）
// FHD16:9：56.25%
// シネマスコープ2.35:1：42.5531%
// 正方形：100%
// 黄金比1.618:1：61.8%
// 白銀比1.414:1：70.721%

@mixin wmpr-aspect-ratio-media($value) {
  position: relative;

  @if $value == std {
    padding-bottom: 75%;
  }
  @else if $value == fhd {
    padding-bottom: 56.25%;
  }
  @else if $value == cinema {
    padding-bottom: 42.5531%;
  }
  @else if $value == square {
    padding-bottom: 100%;
  }
  @else if $value == gold {
    padding-bottom: 61.8%;
  }
  @else if $value == silver {
    padding-bottom: 70.721%;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
  }
}
